/* eslint-disable jsx-a11y/iframe-has-title */
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import "../map/css/index.css"

export default function Map(props) {
    return (
        <div>
            {/* <MapContainer center={[13.7837263,100.5603957]} zoom={13} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[13.7837263,100.5603957]}>
                    <Popup>บริษัท กู๊ดเน็ท โกบอล กรุ๊ป จำกัด</Popup>
                </Marker>
            </MapContainer> */}
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d245.25436005745232!2d100.56040676280799!3d13.783722023769904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d58f5c33ff1%3A0x37ed28fb35035331!2sOFFICE%20READY!5e1!3m2!1sth!2sth!4v1718859435116!5m2!1sth!2sth"
                style={{ width: "100%", height: 400, border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
}

