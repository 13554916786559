/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import Layout, { Content } from "antd/lib/layout/layout"
import { useNavigate } from "react-router-dom"
import Header from "../../common/components/header"
import ClassroomSearch from "./components/search"
import ClassroomBoard from "./ClassroomBoard"
import { getClassroomCourseFetch } from "./API"
import { detectSignIn } from "../../common/mamager/DetectManager"
import LoadingPage from "../../common/components/loading/page"
import Footer from "../../common/components/footer"

export default function Classroom(props) {
    const navigate = useNavigate()

    const [courseClassroom, setCourseClassroom] = useState([])
    const [loading, setLoading] = useState(false)

    const handleClassroomSearch = async (category, rating, text) => {
        setLoading(true)

        const result = await getClassroomCourseFetch({ category, rating, text, learnerId: props.user ? props.user.uid : null })
        console.log("getClassroomCourseFetch : ", result)
        setCourseClassroom(result)
        setLoading(false)
    }

    const detectLogin = async () => {
        // detect login
        const isLogin = await detectSignIn()
        if (!isLogin) {
            navigate("/login")
        }
    }

    useEffect(() => {
        detectLogin()
        handleClassroomSearch(null, 1, null)
    }, [])

    return (
        <Layout className="layout">
            <Header firebase={props.firebase} authorized={props.authorized} user={props.user} />

            <article>
                <Content>
                    {!loading ?
                        <>
                            <ClassroomSearch
                                firebase={props.firebase}
                                authorized={props.authorized}
                                user={props.user}
                                handleClassroomSearch={handleClassroomSearch}
                            />

                            <ClassroomBoard courseClassroom={courseClassroom} user={props.user} />
                        </>
                        :
                        <LoadingPage loading={loading} />
                    }
                </Content>
            </article>

            <Footer />
        </Layout>
    )
}
